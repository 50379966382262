import { Controller } from "@hotwired/stimulus"
import CableReady from 'cable_ready'

export default class extends Controller {
  
  static values = {    
    fetchParams: {
      type: Object,
      default: {}
    }
  }

  static targets = ["form"];

  fetchAndRunOperations(event) {        
    //Fetch from the url (using GET) and execute the response operation
    fetch(this.formTarget.action + '?' + new URLSearchParams(this.fetchParamsValue))
      .then(r => r.json())
      .then(CableReady.perform)      
  }

}

