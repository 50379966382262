import { Controller } from "@hotwired/stimulus"
import CableReady from 'cable_ready'

export default class extends Controller {
  
  initialize() {
  }

  
  updateDocumentCount(event) {        
    //console.log(event.detail);            
    var detail = event.detail;
    var minScore = Math.round(detail.scoreRange[0]);
    var maxScore = Math.round(detail.scoreRange[1]);
    
    //First update some hidden input fields that will set the calibration profile values for future form submits. These shouldn't exist until the score range input is shown

    //Then make a request to server for count of exams and documents between score range. Update score range min/max in event calibration profile.
    
    fetch(detail.fetchUrl + '?' + new URLSearchParams({
      minScore: minScore,
      maxScore: maxScore,
  }))
      .then(r => r.json())
      .then(CableReady.perform)      
    //Replace the exam and document counts displayed on screen.

  }

}

