import { Controller } from "@hotwired/stimulus"
import CableReady from 'cable_ready'


export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    console.debug("controller connected")
    const input = this.inputTarget;

    const end = input.value.length;
    
    // Move focus to END of input field
    input.setSelectionRange(end, end);
    input.focus();
  
  }
  disconnect() {
    console.debug("controller disconnected")
   }



  

}
