import ChartsController from './charts_controller'

export default class extends ChartsController {

  update() {
    this.stimulate('ScoreChart#update', event.target, { serializeForm: true })
  }
  
  get chartOptions() {
    return {
      chart: {
        height: "100%",
        type: 'bar',
        toolbar: {
          tools: {
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
        events: {
          dataPointSelection: (event,chartContext, config) => {
            const dataLabels = config.w.config.xaxis.categories;
            const selectedIndex = config.dataPointIndex;
            const selectedScore = dataLabels[selectedIndex];
            const graderEventId = this.eventValue;
            const questionId = this.questionValue;
            
            //Redirect to view assignments for this event filtered to clicked score. Use only_active_event flag 
            document.location = `/assigned_documents?question_id=${questionId}&grader_event_id=${graderEventId}&only_active_event=y&filter_score=y&min_score=${selectedScore}&max_score=${selectedScore}`;
          }
        }
      },      
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top',            
          }
        }
      },
      xaxis: {
        categories: this.labelsValue,
        tickAmount: 'dataPoints',
        type: 'numeric', 
        decimalsInFloat: 0,
        title: {
          text: "Scores"
        }
      },
      annotations: {
        xaxis: [
          {
            x: this.meanValue,
            borderColor: '#00E396',
            label: {
              borderColor: '#00E396',
              orientation: 'horizontal',
              text: 'Average ' + this.meanValue
            }
          }
        ]
      },
      yaxis: {        
        type: 'numeric', 
        decimalsInFloat: 0,
        title: {
          text: "Examinees Count"
        }
      },
      series: [{
        name: 'Scores',
        data: this.seriesValue
      }]      
    }
  }  
}
