import ApplicationController from './application_controller'
import CableReady from 'cable_ready'


export default class extends ApplicationController {
  connect () {
    super.connect()
  }

  toggle(event) {
    //this.stimulate("Bookmarks#toggle", this.element)
    //Increment or decrement the number of saved bookmarks
    
    
    var qsMap = parseQuery(window.location.search) //Pass the current filters    
    var grader_event_id = document.querySelector("#grader_event_id").value
    var qs = "?grader_event_id=" + grader_event_id;

    var grader_id = qsMap["grader_id"]
    if (qsMap["grader_id"]){      
      qs = qs  + "&grader_id=" + grader_id
    } 
    
    
    fetch(event.params.url+qs)
    .then(r => r.json())
    .then(CableReady.perform)
  }

  beforeRead(element) {
    //TODO replace filled element with empty
    //element.classList.add("opacity-0")
    
  }
  
}
function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}