import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  //static classes = [ "selected" ]

  static targets = [ "input" ]
  static values = { documents: Number }

  resetAssignmentCounts(event){
    const shouldReset = confirm("Are you sure you want to reset the counts for this question?")
    if (!shouldReset){
      return false;
    }
    this.inputTargets.forEach((element, i) => {
      element.classList.remove("lockedDocumentCount")
    })

    const totalDocumentsCount = this.documentsValue;
    
    const remainder_graders = this.inputTargets.length
    const documents_per_grader_without_remainder = Math.floor(totalDocumentsCount / remainder_graders)
    const documents_left = totalDocumentsCount % remainder_graders;
    
    this.inputTargets.forEach((element, i) => {
      const add_extra = (i < documents_left) ? 1 : 0;
      element.value = documents_per_grader_without_remainder + add_extra
    })

  }  

  updateAssignmentCounts(event){
    //When a field changes, add a class to designate it's locked
    event.currentTarget.classList.add("lockedDocumentCount");

    //TODO Add a locked icon next to locked fields
    const totalDocumentsCount = this.documentsValue;

    //Get all input targets, iterate through them. Sum the locked values, store the unlocked elements for later         
    let fixedSum = 0;
    let dynamicInputFields = [];

    this.inputTargets.forEach((element, i) => {
      if (element.classList.contains("lockedDocumentCount")){
        //Fixed field
        let newValue = parseInt(element.value)
        if (fixedSum + newValue  > totalDocumentsCount)
        {
          //Update the value to the max allowed.
          newValue = totalDocumentsCount - fixedSum;
          element.value = newValue;
        }
        fixedSum += newValue;
        
      } else {
        //This field can vary. Add it to a list
        dynamicInputFields.push(element)
      }      
    })
    const remainder = totalDocumentsCount - fixedSum;
    const remainder_graders = dynamicInputFields.length
    
    const documents_per_grader_without_remainder = Math.floor(remainder / remainder_graders)
    const documents_left = remainder % remainder_graders;
    
    dynamicInputFields.forEach((element, i) => {
      const add_extra = (i < documents_left) ? 1 : 0;
      element.value = documents_per_grader_without_remainder + add_extra
    })


  }


}