import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "slideover", "sidebarExpandedContainer", "sidebarClosedContainer", "iconContainer", "ipadIconContainer", "ipadSidebarExpandedContainer", "ipadSidebarClosedContainer", "ipadBodyContainer", "ipadIconOpen", "ipadSidebarShell", "ipadSidebarWindow"]

  connect() {
    this.backgroundHtml = this.backgroundHTML()
    this.visible = false

    if (this.getCookie("dismissNav") == "true"){
      this.toggleIpadLeftnav();
    }
  }

  disconnect() {
    if (this.visible) {
      this.close()
    }
  }

  open() {
    this.visible = true
    document.body.insertAdjacentHTML('beforeend', this.backgroundHtml)
    this.background = document.querySelector(`#slideover-background`)
    this.toggleSlideover()
    
    //Reload if asked
    document.addEventListener("page:reload", () => {
      location.reload();
    }, { once: true })

    // Close the slideover on successful submit
    document.addEventListener("submit:success", () => {
      this.close()
    }, { once: true })
    // Close the slideover on click outside of slideover
    this.background.addEventListener("click", (event) => {      
      if (this.background.contains(event.target)) {
        this.close()
      }
    }, { once: true })
    // Close the slideover on escape keypress
    document.addEventListener("keydown", (e) => {
      if(e.key == 'Escape'){        
        this.close()
      }
    }, { once: true })

  }

  //function getCookie        
  getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1);
          if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
      }
      return "";
  }

  close() {
    this.visible = false
    this.toggleSlideover()
    if (this.background) {
      this.background.classList.remove("opacity-100")
      this.background.classList.add("opacity-0")
      setTimeout(() => {
        this.background.remove()
      }, 500);
    }
  }

  toggleSlideover() {
    this.slideoverTarget.classList.toggle("right-0")
    this.slideoverTarget.classList.toggle("-right-full")
    this.slideoverTarget.classList.toggle("lg:-right-1/3")
  }
  
  backgroundHTML() {
    return `<div id="slideover-background" class="fixed top-0 left-0 w-full h-full z-20"></div>`;
  }

  toggleLeftnav() {
    if (this.sidebarExpandedContainerTarget.dataset.expanded === "open") {
      this.collapseLeftnav()
    } else {
      this.expandLeftnav()
    }
  }
  
  collapseLeftnav() {
    this.sidebarExpandedContainerTarget.classList.add("hidden")
    this.sidebarExpandedContainerTarget.dataset.expanded = "closed"
    this.sidebarClosedContainerTarget.classList.remove("hidden")
  }

  expandLeftnav() {
    this.sidebarExpandedContainerTarget.classList.remove("hidden")
    this.sidebarExpandedContainerTarget.dataset.expanded = "open"
    this.sidebarClosedContainerTarget.classList.add("hidden")
  }

  toggleIpadLeftnav() {
    if (this.ipadSidebarExpandedContainerTarget.dataset.expanded === "open") {
      this.collapseIpadLeftnav()
      document.cookie = 'dismissNav=true; expires=0; path=/';
    } else {
      this.expandIpadLeftnav()
    }
  }
  
  collapseIpadLeftnav() {
    this.ipadSidebarExpandedContainerTarget.classList.add("hidden")
    this.ipadIconOpenTarget.classList.add("hidden")
    this.ipadSidebarExpandedContainerTarget.dataset.expanded = "closed"
    this.ipadSidebarClosedContainerTarget.classList.remove("hidden")
    this.ipadSidebarShellTarget.classList.remove("w-full")
    this.ipadSidebarShellTarget.classList.remove("black-modal")
    this.ipadSidebarShellTarget.classList.add("w-10")
    this.ipadSidebarWindowTarget.classList.add("hidden")
  }
  
  expandIpadLeftnav() {
    this.ipadSidebarExpandedContainerTarget.classList.remove("hidden")
    this.ipadIconOpenTarget.classList.remove("hidden")
    this.ipadSidebarExpandedContainerTarget.dataset.expanded = "open"
    this.ipadSidebarClosedContainerTarget.classList.add("hidden")
    this.ipadSidebarShellTarget.classList.remove("w-10")
    this.ipadSidebarShellTarget.classList.add("black-modal")
    this.ipadSidebarShellTarget.classList.add("w-full")
    this.ipadSidebarWindowTarget.classList.remove("hidden")
  }

}