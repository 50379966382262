import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "accounts-show", "terms", "exams", "admin", "container"]

  toggleTab() {
    if (this.containerTarget.dataset.expanded === "terms") {
      this.selectTerms()
    } 
    if (this.containerTarget.dataset.expanded === "exams") {
      this.selectExams()
    }
    if (this.containerTarget.dataset.expanded === "admin") {
      this.selectAdminUsers()
    }
  }
  
  selectTerms() {
    this.containerTarget.dataset.expanded = "terms"
    this.termsTarget.classList.add("bg-gray-200")
    this.examsTarget.classList.remove("bg-gray-200")
    this.adminTarget.classList.remove("bg-gray-200")
  }
  
  selectExams() {
    this.containerTarget.dataset.expanded = "exams"
    this.termsTarget.classList.remove("bg-gray-200")
    this.examsTarget.classList.add("bg-gray-200")
    this.adminTarget.classList.remove("bg-gray-200")
  }
  
  selectAdmin() {
    this.containerTarget.dataset.expanded = "admin"
    this.termsTarget.classList.remove("bg-gray-200")
    this.examsTarget.classList.remove("bg-gray-200")
    this.adminTarget.classList.add("bg-gray-200")
  }

}