import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["truncate", "contentOpen", "contentClosed", "moreButton", "lessButton"]

  showMore() { 
    this.expandNote()
  }  

  showLess() { 
    this.collapseNote()
  }  
  
  expandNote() { 
    this.contentOpenTarget.classList.remove("hidden")
    this.contentClosedTarget.classList.add("hidden")
    this.moreButtonTarget.classList.add("hidden")
    this.lessButtonTarget.classList.remove("hidden")
  }
  
  collapseNote() { 
    this.contentOpenTarget.classList.add("hidden")
    this.contentClosedTarget.classList.remove("hidden")
    this.moreButtonTarget.classList.remove("hidden")
    this.lessButtonTarget.classList.add("hidden")
  }
}