import { Controller } from "@hotwired/stimulus"
import CableReady from 'cable_ready'


export default class extends Controller {
  static targets = [ "form" ]



  searchUsers(event) {          
    //TODO Debounce this    

    var query = this.formTarget.children[0].value;
    fetch("/users/search?manager="+event.params.manager+"&question_id="+ event.params.question +"&query="+encodeURIComponent(query) )
      .then(r => r.json())
      .then(CableReady.perform)
  }

}
