import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static values = {    
    scoringProfile: Object,
    promptText: String, 
    overrideAllowed: Boolean,
  }
  
  initialize() {
  }
  
  updateDisplayedScore(event) {     
    // When a score is change, the submit button will display differently depending on score is skip or a value      
    
    var newScore = event.srcElement.value;
    

    //When a score is changed, update all select boxes on the page for that score component
    var inputsToChange = document.querySelectorAll("select[name='"+ event.srcElement.name + "']")
    inputsToChange.forEach(sel=>{sel.value = newScore});
  
    var newTotalScore = 0;
     
    // Calculate the total score by summing the scores in the form. Both forms match so either can be used
    // Make sure to only sum one form by using this.element on calling form
    // Any skipped compoenent values will cause a NaN and make the total score - 
    [...this.element.querySelectorAll("select")].forEach(el=>{
        //Check if this element is extra credit. If so, only add the value if it's not a -
        if(el.parentElement.classList.contains("extraCredit") && el.value == ""){          
          return;
        }
        
        newTotalScore += parseInt(el.value)
      })
    
    if (isNaN(newTotalScore)){
      newTotalScore = "-"
    } else {
      //Check min/max capped at the maximum score and floored at the mimimum. Don't need to know which are extra credit. 
      var min_score = this.scoringProfileValue["min_score"]||0
      var max_score = this.scoringProfileValue["max_score"]||6
      if (newTotalScore > max_score){
        newTotalScore = max_score
      } else if (newTotalScore < min_score) {
        newTotalScore = min_score
      }
    }
    
    //Update totals for both forms
    var totalsToChange = document.querySelectorAll("div[name='total_score']");
    
    totalsToChange.forEach(
      totalScore=>{ 
        totalScore.innerHTML = newTotalScore;
      }
    );
    this.changeSubmitButton(newTotalScore)
  }

  changeSubmitButton(newTotalScore) {
    let dtButton = document.querySelector("input[purpose='submit']");
    let ipadButton = document.getElementById("ipad_submit") ;
    
    this.changeText(dtButton, newTotalScore)
    this.changeText(ipadButton, newTotalScore)
  }

  changeText(button, opt) {
    // When selected is skip, its value is ""
    opt === "-" ? button.value = "Skip" : button.value = "Save & Next"
  }

  //Call this on button click - add a confirm if alloweOverride. prevent submit if false. 
  checkOverride(event){
    
    if (this.overrideAllowedValue && !confirm(this.promptTextValue)){
      event.preventDefault();
      event.stopPropagation();
    }
  }
}

