import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "selected" ]

  static targets = [ "slide","select","question" ]
  static values = { index: Number, 
    questionIndexes: {type: Object, default:{"1":"0", "2":"1","3":"0","4":"0", }  }
  }



  next() {
    this.indexValue++
  }

  previous() {
    this.indexValue--
  }

  //TODO change to use questionIndexesChanged and only change visibility for the question that changed
  indexValueChanged() {        
    this.showCurrentSlide()
  }

  updateIndexView(){    
    this.indexValue = event.currentTarget.getAttribute('newIndexValue');
    const question_number = event.currentTarget.getAttribute('question')
    this.questionIndexesValue[question_number] = event.currentTarget.getAttribute('newIndexValue');        
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, i) => {
      const question_number = element.getAttribute('question')
      const index = element.getAttribute('index')          
      element.hidden =  index != this.questionIndexesValue[question_number]      
    })
    //Update select targets to add/remove blue border class
    this.selectTargets.forEach((element, i) => {
      const question_number = element.getAttribute('question')
      const index = element.getAttribute('newIndexValue')          
      if (index == this.questionIndexesValue[question_number]){
        element.classList.add(this.selectedClass)
      } else {
        element.classList.remove(this.selectedClass)
      }
      
    })
  }
}