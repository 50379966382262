import { Controller } from "stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = [ "filepicker", "uploadStatus" ]

  static values = { files: Array,
                    fileCount: Number,
                    filesIndex: Array
                  }

  async uploadDirectory(){

    //console.log(`uploading ${this.fileCountValue} files`)

    let files = this.filepickerTarget.files;
    let uploadCount = 0;
    for (let i=0; i<files.length; i++) {      
      //Only include .metadata and .pdf files
      let relativePath = files[i].webkitRelativePath;
      const filenameParts = relativePath.split(".");      
      if (filenameParts.length > 1){
        const fileExtension = filenameParts[filenameParts.length-1]      
        if (fileExtension.toLowerCase() == "pdf" || fileExtension.toLowerCase() == "metadatapdf") {                
          uploadCount = uploadCount + 1;          
          await this.saveExam(files[i], uploadCount);    
        }
      }
    };
    setTimeout(() => location.reload(), 2000);    
  }

  connect() {    
    //this.submit = debounce(this.submit.bind(this), 200)    
    this.filesValue = [];
    this.filesIndexValue = [];
  }

  previewUploadDirectory(event){
    //console.log("reading file list")
    let output = this.fileDisplayTarget;
    let files = this.filepickerTarget.files;

    let fileList = [];
  
    for (let i=0; i<files.length; i++) {
      let relativePath = files[i].webkitRelativePath;
      //Only include .metadata and .pdf files
      const filenameParts = relativePath.split(".");      
      if (filenameParts.length > 1){
        const fileExtension = filenameParts[filenameParts.length-1]      
        if (fileExtension.toLowerCase() == "pdf" || fileExtension.toLowerCase() == "metadatapdf") {
          fileList.push(files[i]);
        }
      }
    };
    this.filesValue = fileList;
    this.fileCountValue = this.filesValue.length;
    //console.log(`Found ${this.filesValue.length} files`)    
    this.uploadStatusTarget.innerHTML = `Click the "Add files" button to begin transferring ${this.filesValue.length} files`;
  }

  async saveExam(examFile,uploadCount) 
  {
      let formdata = new FormData();    
      formdata.append("exams[]", examFile);
      const ctrl = new AbortController(); // timeout
      setTimeout(() => ctrl.abort(), 10000);
      
      try {
        let r = await fetch('/exam_uploads', 
          {method: "POST", body: formdata, signal: ctrl.signal}); 
        //console.log('HTTP response code:',r.status); 
        this.uploadStatusTarget.innerHTML = `Uploaded ${uploadCount} of ${this.fileCountValue}`;
      } catch(e) {        
        this.uploadStatusTarget.innerHTML = `There was an error uploading the exam: ${e}`;
      }      
  }  
}

