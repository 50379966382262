import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  
  static targets = [ "minScore", "maxScore", "filterForm" ]

  initialize() {
  }

  
  updateScoreFilter(event) {        
    //console.log(event.detail);            
    var detail = event.detail;
    var minScore = Math.round(detail.scoreRange[0]);
    var maxScore = Math.round(detail.scoreRange[1]);
    
    //First update some hidden input fields 
    this.minScoreTarget.value = minScore;
    this.maxScoreTarget.value = maxScore;
    //Then submit form request to server 
    
    this.filterFormTarget.requestSubmit();
    //Replace the exam and document counts displayed on screen.

  }

}

