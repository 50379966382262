import { Controller } from "@hotwired/stimulus"
import CableReady from 'cable_ready'


export default class extends Controller {

  static targets = [ "calibrationDocumentDetail" ]
  static values = {    
    eventId: String,    
  }
  static classes = [ "selected" ]

  
  loadDetail(event) {        
    //First highlight the selected row
    setSelected(this.element, this.selectedClass)

    //Then fetch url and run cable operations that should include replacing the content of ther target  
    fetch(event.params.url)
      .then(r => r.json())
      .then(CableReady.perform)
  }

}

//Clear other selected siblings
const setSelected = (el, selectedClass) => {
  [...el.parentElement.children].forEach(sib => sib.classList.remove(selectedClass))
  el.classList.add(selectedClass)
  }
