import { Controller } from "@hotwired/stimulus"
import CableReady from 'cable_ready'


export default class extends Controller {

  static targets = [ "form" ]
  static values = {        
  }
  static classes = [ ]

  addNewRow(event) {
    console.log("here!")
  }

  removeRow(event) {
    console.log("here!")
  }
  
  update(event) {
    //Update the specific score setting, get them from the updated item
    var changedElement = event.srcElement
    console.log(event)
    
    var changedElementValue = changedElement.value
    if (changedElement.type == "checkbox")
    {
      changedElementValue = changedElement.checked
    }
    
    //Get url from form
    //Fetch url and run cable operations that should include replacing the content of ther target  
    const scoring_update_url = this.formTarget.action + "?" + new URLSearchParams({
      changedElementName: changedElement.name,
      changedElementValue: changedElementValue,
    });

    console.log(scoring_update_url)
    fetch(scoring_update_url)
      .then(r => r.json())
      .then(CableReady.perform)
      .catch(error => {
        console.error('Error:', error);
      });
  }
}