import ChartsController from './charts_controller'

export default class extends ChartsController {

  update() {
    //this.stimulate('GraderScoreChart#update', event.target, { serializeForm: true })
  }
  
  get chartOptions() {
    return {
      chart: {
        height: "50px",
        type: 'bar',
        sparkline: {
          enabled: true
        },
        toolbar: {
          show: false,
          tools: {
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      annotations: {
        position: "back",
        yaxis: [
          {
            y: 0,            
            borderColor: '#00E396',
            label: {
              offsetY: -30,
              borderColor: '#00E396',
              style: {
                color: '#fff',
                background: '#00E396'
              },
              text: this.annotationValue
            }
          }
        ]
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [{
              from: -200,
              to: -2,
              color: '#F15B46'
            }, {
              from: -2,
              to: 0,
              color: '#FEB019'
            }, {
              from: 0,
              to: 2,
              color: '#10B981'
            }, {
              from: 2,
              to: 200,
              color: '#4B92D5'
            }
          ]
          },
          columnWidth: '80%',
        }
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        title: {
          
        },
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        }        
      },
      yaxis: {
        show: false,
        type: 'numeric',
        min: -6,
        max: 6,
        forceNiceScale: false,
        decimalsInFloat: 1,
        title: {
          text: "Difference"
        },
        labels: {
          
        }, 
        tickAmount: 3,
      },
      series: [{
        name: 'Difference from question average',
        data: this.seriesValue
      }]
    }
  }  
}
